.main {
    text-align: center;
    color: #fff;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.score {
    position: absolute;
    width: 100%;
    text-align: right;
    top: 0.5rem;
    right: 2rem;
    font-size: 2rem;
}

.draggablediv {
    position: absolute;
    /* width: 100px;
    height: 150px;
    background-color: #f00;
    cursor: move; */
    bottom: 2%;
}

.number {
    font-size: 3rem;
    font-weight: 600;
}

.timer {
    position: absolute;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    margin: 0.5rem 4rem;
}

.mobile-view .score {
    top: 0;
    right: 0;
}

.mobile-view .score {
    font-size: 1rem;
}

.mobile-view .number {
    font-size: 2rem;
    font-weight: 600;
}

.mobile-view .timer {
    font-size: 1rem;
    margin: 0;
}
/* .mobile .draggablediv {
    bottom: 0.5%;
} */

.game-scr-animation {
    position: absolute;
    width: 100vw;
    /* height: 100vh; */
    z-index: -1;
}


/* Field params */
.field {
    height: 110vh;
    /* background-color: #F8F8F8;
    border: .5px solid rgba(130, 130, 130, 0.5); */
    position: relative;
    overflow-y: hidden;
    /* margin: 1em 2em; */
}

/* .mobile .field {
    height: 100vh;
} */
/* Dot positioning */
.dot {
    /* border: .5px solid rgba(130, 130, 130, 0.5); */
    /* opacity: 0.8; */
    /* border-radius: 50%; */
    background-size: contain;
    background-clip: content-box;
    background-repeat: no-repeat;
    position: absolute;
}

p {
    margin: .5em;
}

button {
    background-color: #F8F8F8;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    padding: 6px 18px;
    margin: 12px;
    color: #303030;
    letter-spacing: 0.1em;
}

.in-iframe-resizable {
  transform: scale(0.85);
  /* width: 90vw;
  height: 80vh; */
}

@font-face {
    font-family: "MerkurSansRegular";
    src: url("/css/Webfonts/EOT/MerkurSans-Regular-web.eot");
    src: url("/css/Webfonts/WOFF/MerkurSans-Regular-web.woff") format("woff"),
    url("/css/Webfonts/WOFF2/MerkurSans-Regular-web.woff2") format("woff2"),
    url("/css/Webfonts/OTF/MerkurSans-Regular-web.otf") format("opentype");
    }
    
  @font-face {
    font-family: "MerkurSansBlack";
    src: url("/css/Webfonts/EOT/MerkurSans-Black-web.eot");
    src: url("/css/Webfonts/WOFF/MerkurSans-Black-web.woff") format("woff"),
    url("/css/Webfonts/WOFF2/MerkurSans-Black-web.woff2") format("woff2"),
    url("/css/Webfonts/OTF/MerkurSans-Black-web.otf") format("opentype");
    }
  
  @font-face {
    font-family: "MerkurSansCondensedBlack";
    src: url("/css/Webfonts/EOT/MerkurSans-CondensedBlack-web.eot");
    src: url("/css/Webfonts/WOFF/MerkurSans-CondensedBlack-web.woff") format("woff"),
    url("/css/Webfonts/WOFF2/MerkurSans-CondensedBlack-web.woff2") format("woff2"),
    url("/css/Webfonts/OTF/MerkurSans-CondensedBlack-web.otf") format("opentype");
    }
  body {
    font-size: 24px;
    background-color: #012045;
    font-family: 'MerkurSansRegular', Arial, sans-serif;
    font-weight:normal;
    font-style:normal;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  
  button,
  textarea,
  input,
  select,
  .btn,
  .chest,
  .hitTarget,
  a{
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-tap-highlight-color: transparent;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
    user-select: none;
  }
  .App {
    text-align: center;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  main {
    max-height: 98vh;
    height: 100%;
    min-width: 100%;
    width: auto;
    margin: auto;
    position: relative;
    overflow: hidden;
    /* overflow-x: hidden; */
  }
  
  .mobile main {
    width: 100%;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    position: relative;
    background-color: rgb(21, 20, 20);
    /* min-height: 100vh; */
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .animation-container {
    background-color: red;
  }
  .level-animation-container {
    position: relative;
    background-color: rgb(21, 20, 20);
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  .intro-heading {
      position: absolute;
      z-index: 150;
      left: 0;
      right: 0;
      width: 60%;
      top: 15%;
      margin: auto;
  }
  .desktop-view {
    /* width: 85%; */
    margin: 0 auto;
  }
  .desktop-view,
  .mobile-view {
    position: relative;
  }
  .mobile-view .intro-heading {
    width:80%;  
    top: 12%;
  }
  .intro-heading-totalbonus {
    font-size: clamp(16px, 6vw, 50px);
    color: #ffcb05;
    font-weight: lighter;
  }
  .continueSession-msg {
    font-family: 'MerkurSansBlack', Arial, sans-serif;
    opacity: 0;
    transition: opacity 2.5s ease-in-out;
    position: absolute;
    z-index: 150;
    left: 0;
    right: 0;
    /* min-width: 320px; */
    min-width: clamp(220px,90vw,640px);
    width: 85%;
    top: 34%;
    margin: auto;
    color: #fff;
    /* font-size: clamp(15px,7vw,60px); */
    font-size: clamp(14px,8vw,40px);
    /* font-weight: bolder; */
    /* opacity: 0; */
  }
  .intro-heading-title {
    color: #fff;
    font-size: 34px;
    font-weight: bolder;
    display: none;
  }
  .intro-heading-logo img {
    max-width: 200px;
    display: none;
  }
  
  .c-elements {
    position: absolute;
  }
  
  .chests {
    display: flex;
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 37%;
    left: -4vw;
    flex-direction: column;
    align-items: center;
    transform: scale(0.75);
  }
  
  .ind-score {
    position: absolute;
    z-index: -1;
    bottom: 15%;
    width: 150px;
    margin: 0 auto;
    font-size: x-large;
    opacity: 0;
    font-weight: bolder;
    font-family: MerkurSansCondensedBlack,Arial,sans-serif;
  }
  .positive {
    color: #fff;
  }
  .negative {
    color: #da0303;
  }
  .points-show {
    animation: fadeInFromBottom 1s linear;
    animation-fill-mode: forwards;
  }
  .chests-container {
    display: flex;
  }
  .chest-wrapper {
    opacity: 0;
    animation: fadeIn 2s 2s ease-out;
    animation-fill-mode: forwards;
    z-index: 1;
  }
  .chest-wrapper .points {
    /* background-color: greenyellow; */
    font-family: 'MerkurSansBlack', Arial, sans-serif;
    font-weight: bold;
    position: absolute;
    color: #fff;
    font-size: min(max(20px,1.5rem),90px);
    /* transform: translateY(-430px); */
    opacity: 0.2;
    display: block;
    /* transition: opacity 0.5s ease-out 2.5s; */
    animation: fadeOut 0.5s ease-out;
    animation-fill-mode: forwards;
    z-index: 100;
    text-align: center;
    /* left: 2%; */
    /* right: 5rem; */
    line-height: 1.25rem;
    /* bottom: 0; */
    width: 100%;
    top: -20%;
  }
  .chest-wrapper .points.negative {
    color: #da0303;
    /* bottom: 35% !important; */
    top: -80%;
  }
  .mobile-view .chest-wrapper .points.negative {
    /* top: -50%; */
    /* bottom: 33% !important; */
    transform: scale(1);
  }
  .mobile-view .points {
    font-size: min(max(20px,1rem),38px) !important;
    line-height: clamp(8px, 2vw, 20px);
    /* right: 25%; */
    transform: scale(1.2);
  }
  .mobile-view .points .small-text {
    font-size: clamp(8px, 2vw, 20px);
  }
  
  
  .small-text {
    font-size: 24px;
    font-weight: 500;
    
    font-family: 'MerkurSansRegular', Arial, sans-serif;
    font-weight:normal;
  }
  .chest-wrapper .points.show,
  .mobile-view .chest-wrapper .points.show { 
    /* transform:  translateY(-290px); */
    /* bottom: 38%; */
    top: -80%;
    animation: fadeInFromBottom 0.5s ease-out;
    animation-fill-mode: forwards;
    display: block;
    /* margin-top: 200px; */
    opacity: 1 !important;
  }
  .chest-wrapper .hide  .finger-container {
    opacity: 0;
  }
  .chest-wrapper .finger-container {
    transition: opacity 0.5s ease-out 0.3s;
  }
  .kovcheg-anim_full {
    bottom: 0px;
    margin: -8px;
  }
  .chest {
    /* background-color: red;   */
    transform: scale(1);
    display: flex;
  }
  .chest.k1, .chest.k2 {
    margin-left: 7%;
    margin-right: -7%;
    margin-top: -28%;
    margin-bottom: 28%;
  }
  .hitTarget {
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    left: 35%;
    right: 0;
    bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(128, 255, 0, 0);
    /* max-height: 5vw; */
    height: 23%;
  }
  
  .mobile-view .chest .hitTarget {
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(128, 255, 0, 0);
    height: 25%;
  }
  
  
  .mobile-view .chests {
    transform: scale(0.95);
    top: 52%;
    left: -5%;
  }
  .mobile-view .chest.k1, .mobile-view .chest.k2 {
    margin-left: 10%;
    margin-right: -10%;
    margin-top: -30%;
    margin-bottom: 30%;
  }
  .mobile-view .chest .percent {
    /* font-size: 6px; */
  }
  
  .chest  .false {
    display: none;
  }
  
  .finger-container {
    position: absolute;
    width: 20%;
    top: 30%;
    left: 61%;
  }
  .mobile-view .finger-container {
    transform: scale(0.75);
  }
  
  .button_getbonus,
  .button_endgame,
  .button_next,
  .button_getbonus_container {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: 200;
    /* max-width: 30%; */
  }
  .button_next {
    /* animation-name: swing-lr;
    animation-duration: 1s;  
    animation-iteration-count: infinite; */
  }
  
  .mobile-view .btn {
    /* max-height: 26px; */
    /* position: relative; */
    /* bottom: 30px; */
    width: 46vw;
    margin: 0 auto;
    /* margin-top: -10px; */
  }
  .mobile-view .btn svg {
    max-height: 60px;
    width: auto;
  
  }
  .mobile-view .btn img {
    max-height: 40px;
    width: auto;
  }
  .mobile-view .finalPoints {
    top: 23%;
  }
  .button_getbonus {
    
  }
  .button_check_account {
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 0px;
    left: 0;
    z-index: 200;
    text-align: center;
  }
  .button_start {
    width: 100%;
    position: absolute;
    bottom: 500px;
    right: 0px;
    left: 0;
    z-index: 200;
    text-align: center;
  }
  .button_getbonus img,
  .button_start img {
    max-width: 240px;
  }
  .button_check_account img {
    max-width: 340px;
  }
  .intro-scr-animation-container .continue_btn {
    bottom: 8%;
  }
  .intro_btn {
    width: 100%;
    position: absolute;
    bottom: 5% !important;
  }
  .intro_btn img {
    opacity: 0;
    cursor: pointer;
    padding: 1%;
  }
  .leaderboard {
    position: absolute;
    width: 100%;
    z-index: 1000;
    color: #fff;
    margin: 0 auto;
    top: 30%;
  }
  .leaderboard__title {
    color: #fff;
  }
  .leaderboard__row {
    line-height: 100%;
    font-size: clamp(14px,3vw,21px);
  }
  .leaderboard__row td:last-child {
    text-align: left;
  }
  .leaderboard__row td:last-child {
    text-align: right;
  }
  .leaderboard__table {
    /* position: absolute; */
    width: 50%;
    margin: 0 25%;
    /* top: 100%; */
  }

  td:nth-child(1) {
    width: 10%;
  }

  td:nth-child(2) {
    width: 40%;
  }

  td:nth-child(3) {
    width: 15%;
  }

  td:nth-child(4) {
    width: 35%;
  }

  .desktop-view hr {
    width: 49%;
    text-align: center;
    margin-left: 26%;
  }

  .mobile-view hr {
    /* width: 49%;
    text-align: center;
    margin-left: 26%; */

    width: 66%;
    text-align: center;
    /* margin-left: 17%; */
    margin: 1% auto;
  }

  /* .mobile-view .leaderboard__text.end {
    width: 80%;
    margin: 0 10%;
    top: 38.5%;
  } */
  .leaderboard__row--highlight {
    /* background-color: #f3f3f3; */
    color:rgb(255, 204, 0);
  }  

  .leaderboard__text {
    /* position: absolute;
    top: 36%;
    width: 56%;*/
    margin: 0 10%; 
    width: 80%;
    font-family: "MerkurSansBlack";
    font-size: clamp(16px,6vw,26px);
    color: rgb(255, 204, 0);
    z-index: 1000;
    margin-bottom: 2%;
  }
  .leaderboard.end {
    top: 35%;
  }
  .mobile-view .leaderboard.end {
    top: 40%;
  }
  .mobile-view .leaderboard__table {
    width: 68%;
    margin: 0 16%;
  }
  .mobile-view .leaderboard__text {
    /* top: 39%;
    width: 64%;
    margin: 0 18%; */
    font-size: clamp(10px,3.5vw,20px);
  }
  .mobile-view .leaderboard__row {
    line-height: 90%;
    font-size: clamp(12px,3vw,18px);
  }

  .mobile-view .intro_btn {
    bottom: 6.5% !important;
  }
  .mobile-view .intro_btn img {
    width: 25%;
  }
  /* .mobile-view .leaderboard__text {
    font-size: clamp(10px,4vw,16px);
  } */
  .mobile-view .leaderboard__text.board {
    font-size: clamp(10px,4vw,16px);
    top: 31%;
    width: 80%;
    margin: 0 10%;
  }
  .btn {
    position: absolute;
    text-align: center;
    bottom: 26%;
    left: 0;
    right: 0;
    z-index: 2000;
    height: 7%;
    width: 44%;
    margin: 0 auto;
  }

  .welcome-scr-animation-container .btn {
    bottom: 16% !important;
  }

  .mobile-view .welcome-scr-animation-container .btn {
    bottom: 19% !important;
  }

  .slider {
    transition: opacity 0.4s;
  }

  .slider.horizontal {
    position: absolute;
    top: 12%;
    left: 3%;
    width: 23.3%;
    height: 8%;
    z-index: 100;
    background: transparent url('/images/game/slider-horizontal-bg.png')no-repeat;
    background-size: con  tain;
  }
  .slider.vertical {
    position: absolute;
    top: 11%;
    right: 2%;
    width: 5%;
    height: 31%;
    z-index: 100;
    /* transform: rotate(90deg); */
    background: transparent url('/images/game/slider-vertical-bg.png') no-repeat;
    background-size: contain;
  }
  .mobile-view .slider.horizontal {
    /* bottom: 12%; */
    width: 33%;
    height: 10%;
    top: 11%;
  }
  .mobile-view .slider.vertical {
    height: 25%;
    width: 10%;
    top: 9%;
    right: 1%;
  }
  .small-ball {
    position: absolute;
    /* width: 50%;
    height: 50%; */
    background: url('/images/game/circle.png') no-repeat 0 0 transparent;
    background-size: contain;
  }
  .slider.horizontal .small-ball {
    margin-top: 1.5%;
    left: 1%;
    width: 52%;
    height: 52%;
  }
  .slider.vertical .small-ball {
    top: 1%;
    width: 67%;
    height: 67%;
    margin-left: 4%;
  }
  .mobile-view .slider.horizontal .small-ball {
    margin-top: 1%;
    left: 2%;
    width: 34%;
    height: 34%;
  }
  .mobile-view .slider.vertical .small-ball {
    left: 0%;
    top: 2%;
    width: 45%;
    height: 45%;
  }
  .slider.horizontal.anim1 .small-ball, .mobile-view .slider.horizontal.anim1 .small-ball {
    -webkit-transition: left 0.95s linear;
    -moz-transition: left 0.95s linear;
    -ms-transition: left 0.95s linear;
    -o-transition: left 0.95s linear;
    transition: left 0.95s linear;
  }
  .slider.vertical.anim1 .small-ball, .mobile-view .slider.vertical.anim1 .small-ball {
    -webkit-transition: top 0.95s linear;
    -moz-transition: top 0.95s linear;
    -ms-transition: top 0.95s linear;
    -o-transition: top 0.95s linear;
    transition: top 0.95s linear;
  }

  .slider.horizontal.anim2 .small-ball, .mobile-view .slider.horizontal.anim2 .small-ball {
    -webkit-transition: left 0.65s linear;
    -moz-transition: left 0.65s linear;
    -ms-transition: left 0.65s linear;
    -o-transition: left 0.65s linear;
    transition: left 0.65s linear;
  }
  .slider.vertical.anim2 .small-ball, .mobile-view .slider.vertical.anim2 .small-ball {
    -webkit-transition: top 0.65s linear;
    -moz-transition: top 0.65s linear;
    -ms-transition: top 0.65s linear;
    -o-transition: top 0.65s linear;
    transition: top 0.65s linear;
  }
  
  /* .slider.horizontal .small-ball.one-end {
    left: 2%;
  }
  
  .slider.horizontal .small-ball {
    left: 83%;
  }   */
  /* .slider.horizontal .small-ball {
    margin-top: 1.25%;
  }
  .slider.vertical .small-ball {
    margin-left: 5%;
  } */

  /* .slider.vertical .small-ball.one-end {
    top: 2%;
  }
  
  .slider.vertical .small-ball.other-end {
    top: 83%;
  } */

  /* .mobile-view .slider.horizontal .small-ball.one-end {
    left: 5px;
  }
  
  .mobile-view .slider.horizontal .small-ball {
    left: 240px;
  }  
  .mobile-view .slider.vertical .small-ball.one-end {
    top: 10px;
  }
  
  .mobile-view .slider.vertical .small-ball.other-end {
    top: 240px;
  } */
  .intro.continue_btn img {
    position: absolute;
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 6% !important;
    opacity: 0;
    cursor: pointer;
    height: 9%;
  }

  .continue_btn, .game_btn {
    bottom: -79% !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
    z-index: 10000;
  }

  /* .game_btn {
    position: absolute;
    bottom: 4% !important;
    left: 10%;
    right: 10%;
  } */
  .intro-scr-animation-container .game_btn {
    opacity: 0;
  }
  .grinch-animation {
    position: absolute;
    z-index: 10000;
    display: block;
    top: 68%;
    width: 15%;
    height: 25%;
    transform: translateY(-65%);
    /* transform-origin: -50% bottom; */
    /* left: 43%;
    right: 43%; */
    opacity: 0;
    transition: left 1.2s ease-in-out, right 1.2s ease-in-out, top 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s linear;
  }
  .grinch-animation svg {
    transition: left 1.2s linear, right 1.1s linear, top 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s linear;

  }
  .mobile-view .grinch-animation {
    top: 68%;
    width: 20%;
    height: 33%;
  }
  .hitting-grid {
    position: absolute;
    display: block;
    z-index: 10000;
    /* width: 28%; */
    left: 34%;
    right: 29%;
    top: 22%;
  }
  .mobile-view .hitting-grid {
    left: 30.5%;
    right: 24.5%;
    top: 30%;
  }
  .grid-row {
    /* width: 100%; */
    /* border: 2px solid #ff0000; */
    height: 10%;
    display: flex;
  }
  .grid-cell {
    width: 10%;
    /* border: 1px inset #ff5ff5; */
    display: block;
    justify-content: center;
    align-items: center;
  }
  .mobile-view .grid-row {
    height: 3.75vh;
  }
  .finger-left, .finger-right {
    position: absolute;
    width: 20%;
    height: 20%;
    z-index: 10000;
    /* top: 23%; */
    transform: rotate(120deg);
    transition: opacity 0.5s linear 0.5s;
  }
  .finger-left {
    top: 19%;
    left: 8%;
    opacity: 0;
  }
  .finger-right {
    right: 5%;
    top: 25%;
    transform: rotate(270deg);
    opacity: 0;
  }

  /* .mobile-view .finger-left, .mobile-view .finger-right {
    z-index: 10000;
    top: 15%;
  } */
  .mobile-view .finger-left {
    top: 16%;
    left: 5%;
    transform: rotate(155deg);
    opacity: 0;
  }
  .mobile-view .finger-right {
    right: 14%;
    top: 6%;
    transform: rotate(340deg);
    opacity: 0;
  }
  .pause {
    display: block;
    left: 30%;
    width: 50%;
    position: absolute;
    z-index: 100000;
  }

  .grid-cell img {
    opacity: 0;
    transition: opacity 0.5s ease-in-out 0.5s;
  }
  .game_btn.horizontal {
    /* bottom: 14% !important; */
  }
  .game_btn.vertical {
    /* left: 50%; */
  }
  .desktop-view .continue_btn img {
    cursor: pointer;
    opacity: 0;
  }
  .grid {
    display: grid;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
}

.grid > div {
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}
  .ball img {
    position: absolute;
    opacity: 0;
    bottom: 34.5%;
    z-index: 12000;
    width: 5%;
    transform: scale(2);
    -webkit-transition: opacity 0.5s ease-in-out 0.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1);
    -moz-transition:  opacity 0.5s ease-in-out 0.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1);
    -ms-transition: opacity 0.5s ease-in-out 0.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1);
    -o-transition: opacity 0.5s ease-in-out 0.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1);
    transition: opacity 0.5s ease-in-out 0.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1);
  }
  /* .mobile-view .ball img {
    bottom: 34.5%;
  } */
  .ball img.x2 {
    width: 2.5%;
    padding-top: 10%;
    padding-left: 2.5%;
  }
  .mobile-view .ball img.x2 {
    padding-top: 20%;
  }
  .mobile-view .btn.continue_btn {
    bottom: 5% !important;
    opacity: 0;
  }

  .mobile-view .intro.continue_btn img {
    position: absolute;
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 6% !important;
    opacity: 0;
  }

  .start_btn {
    opacity: 0;
    transform: translateY(300px);
    transition: all 0.5s ease;
    cursor: pointer;
    margin: 0 auto;
  }
  .start_btn.show {
    opacity: 1;
    transform: translateY(0);
    height: 100%;
  }
  
  /* .start_btn {
    max-width: 310px;
  } */
  .c-container {
    position: relative;
  }
  .c-container1 {
    display: none;
  }
  .c-container .finalPoints {  
    /* align-content: stretch; */
    color: #fff;
    font-family: MerkurSansCondensedBlack,Arial,sans-serif;
    font-size: clamp(3.8rem,15vw,22rem);
    /* font-weight: bolder; */
    /* height: 100%; */
    /* left: 0; */
    /* line-height: 1.8rem; */
    opacity: 0;
    position: absolute;
    /* right: 0; */
    text-align: center;
    top: 42%;
    width: 100%;
    z-index: 300;
  }

  .mobile-view .c-container.end {
    width: 98vw;
    margin: 0 auto;
  }

  .desktop-view .end-session .c-container .finalPoints {
    top: 40% !important;
    font-size: clamp(12rem,16vw,20rem);
  }
  
  .mobile-view .c-container .finalPoints {
    line-height: 1.4rem !important;
    /* animation: fadeIn 0.5s ease-out 3s;
    animation-fill-mode: forwards; */
    top: 49% !important;
    /* animation: fadeIn 0.5s ease-out 4.5s; */
  }
  .desktop-view .c-container .finalPoints.show,
  .mobile-view .c-container .finalPoints.show { 
    animation: fadeIn 0.5s ease-out 3.5s;
    animation-fill-mode: forwards;
  }
  .c-container .small-text {
    font-size: 2rem;
  }
  
  .mobile-view .c-container .small-text {
    font-size: 1.4rem !important;
  }
  
  .mobile-view .end-session .c-container .finalPoints {
    top: 56% !important;
    font-size: clamp(7rem,10vw,14rem);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

/* @keyframes fadeInFromBottom {
    from {
        bottom: 7.5%;
        opacity: 0;
      }
      to {
        bottom: 15%;
        opacity: 1;
    }
} */

@keyframes fadeInFromBottom {
  0%   {bottom: 15%; opacity: 0;}
  25%  {bottom: 18%; opacity: 0.6;}
  50%  {bottom: 21%; opacity: 1;}
  75%  {bottom: 24%; opacity: 0.6;}
  100% {bottom: 25%; opacity: 0;}
}

  @keyframes fadeOut {
    from {
      top: -80%;
      opacity: 1;
    }
    to {
      top: -80%;
      opacity: 0;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .session-end {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
  }
  /* .btn {
    background-color: transparent;
    border: none;
    bottom: 0;
  } */
  .btn img {
    width: auto;
    height: 100%;
    max-height: 100px;
  }
  .endScr-msg {
    font-size: 24px;  
    font-family: 'MerkurSansRegular', Arial, sans-serif;
    color: white;
    
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 10000;
  }
  .endScr-msg > div {
    max-width: 780px;
    margin: auto;
    padding: 10px;
  }
  
  @media only screen and (min-width: 1921px) and (max-width: 2200px){
    .upper-column {
      width: 58%;
    }
    .chests {
      top: 41%;
      left: -3%;
    }
  }
  
  @media only screen and (min-width: 2201px){
    .upper-column {
      width: 50%;
    }
    .chests {
      top: 42%;
      left: -3%;
    }
  }
  
  @media only screen and (max-width: 768px){
    /* .chests-container {
      margin: auto 45px auto 40px;
      
    }
    .chest-wrapper {
      margin-bottom: -25px;
    } */
    .endScr-msg {
      font-size: 14px;
    }
  }
  
  @keyframes swing-lr {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  .loaderIcon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000;
    
    background-color: rgba(2, 20, 41, 0.658);
  }
  .continue-game {
    position: relative;
    /* background-color: rebeccapurple; */
  }
  .mobile-view .continue-game .continueSession-msg {
    font-family: 'MerkurSansBlack', Arial, sans-serif;
    top: 32% !important;
    font-size: clamp(16px,9vw,30px);
    /* font-size: clamp(25px,15vw,90px); */
  }

  .vreme {
    font-family: 'MerkurSansRegular', Arial, sans-serif;
    font-size: clamp(14px,6vw,32px);
  }
  
   .hide-msg {
    opacity: 0;
  }
  .show-msg {
    opacity: 1;
  }
  /*.btn-hide {
    display: none;
  }
  .show {
    opacity: 1;
    display: block;
  } */

  .hide {
    display: none;
  }